<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Dados do agendamento</h2>
        <hr />
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="row">
            <div class="col-md-12">
              <v-text-field
                type="text"
                disabled
                :value="nomePaciente"
                label="Paciente"
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                type="text"
                disabled
                :value="tipo"
                label="Tipo de agendamento"
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                type="date"
                disabled
                :value="dataAgendamento"
                label="Data do agendamento"
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                :value="horarioInicio"
                label="Horário Inicial"
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                :value="horarioFinal"
                label="horário Final"
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                :value="especialidade"
                label="Especialidade"
              ></v-text-field>
            </div>
          </div>

          <router-link to="/ListaAgendamentos" v-slot="{ href, navigate }">
            <a :href="href" class="menu-link" @click="navigate">
              <v-btn class=" textStyle" tile color="#1dd1a1">
                <span style="color: white">Voltar</span>
              </v-btn>
            </a>
          </router-link>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
export default {
  name: "VisualizarAgendamento",
  async created() {
    try {
      const { data } = await ambulatorio.indexAgendamento(
        this.$route.params.id
      );

      this.nomePaciente = data.paciente_agendado.nome;
      this.dataAgendamento = data.data;
      this.horarioInicio = data.horario_inicio + "";
      this.horarioFinal = data.horario_final + "";
      this.tipo = data.tipo;
      this.especialidade = data.especialidade.descricao;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      nomePaciente: "",
      dataAgendamento: "",
      horarioInicio: "",
      horarioFinal: "",
      tipo: null,
      especialidade: ""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Visualizar dados do agendamento" }
    ]);
  }
};
</script>
